// eslint-disable-next-line import/prefer-default-export
export const professionalOverviewHero = {
  title: "Grow your small business with a POS for professional services",
  subtext:
    "You work hard as the owner of your business—that’s a fact. Let SpotOn lighten the load and have our technology do more of the work, so you don’t have to.",
  heroImg: "homepage-professional-services.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/demo",
  },
};
export const professionalOverviewTrusted = [
  {
    title: "91%",
    subtext:
      "Of millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of customers search online before visiting a business",
  },
  {
    title: "61%",
    subtext: "Of clients want to receive marketing emails",
  },
  {
    title: "31%",
    subtext: "Of repeat clients spend more than new clients",
  },
];

export const professionalOverviewWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "A true partner in your success",
  content:
    "Professional service businesses aren’t like other small businesses, and yet providers often try to fit you into a box with one-size-fits-all products. At SpotOn, we’ll work closely with you to put together a set of tools that makes sense for you, whether it’s a simple way to schedule appointments, take payments, invoice clients, or simply improve client retention. And we’ll continue to earn your business every day with local service and 24/7 support included.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/demo",
  },
};
export const professionalOverviewSubverticals = {
  title: "Here are a few of the business types we help",
  subverticalInfo: [
    {
      target: "/professional-services/accountants",
      iconImg: "accountant.png",
      subverticalName: "Accountants",
      subverticalContent:
        "Create a seamless digital experience for your clients that’s designed to help your business improve efficiency and grow.",
    },
    {
      target: "/professional-services/plumbers",
      iconImg: "plumber.png",
      subverticalName: "Plumbers",
      subverticalContent:
        "Attract new customers and create new ways for customers to pay with simple, powerful tools that save you time and money.",
    },
  ],
};

export const professionalOverviewMultiSection = {
  sectionTitle: "The right tools for the job",
  featureBlocks: [
    {
      blockTitle: "Run payments. Send invoices. Get paid",
      blockSubTitle:
        "Whether you do most of your work in a back office or on the road, SpotOn Virtual Terminal makes it easy to get paid in a way that’s convenient for you and your clients.",
      blockImg: "prof-a.png",
      blockList: [
        "Take payments online or from your mobile phone",
        "Send invoices online",
        "Run recurring payments with card-on-file ",
        "No payment hardware needed",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/demo",
      },
    },
    {
      blockTitle: "More ways to book appointments. More customers",
      blockSubTitle:
        "Simplify the appointment-booking process, checkout, employee scheduling, and improve client retention with SpotOn Appointments.",
      blockList: [
        "Online booking",
        "Custom service menu",
        "Automated appointment reminders",
        "Simple calendering",
        "Integrated payments",
      ],
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/demo",
      },
      blockImg: "prof-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Help clients discover you with a new website",
      blockSubTitle:
        "It takes about 40 hours to design your own website. Our specialists will build a stunning site for you at a do-it-yourself price in just 2 days. Build your online brand and grow your business.",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom URL",
        "Self-service dashboard",
        "Optional logo design",
        "Lifetime support",
      ],
      blockImg: "prof-c.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/demo",
      },
    },
    {
      blockTitle: "More clients. Less effort",
      blockSubTitle:
        "You want clients to request your services frequently, and be satisfied when they do. Make it easy to engage with them and offer unique deals so that they book appointments more often. With our integrated marketing software, you’ll be able to send branded emails and deals quickly and easily to every client who has booked an appointment or paid through your virtual terminal.",
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/demo",
      },
      blockImg: "prof-d.png",
      forceReverse: false,
    },
    {
      blockTitle: "Monitor and manage customer reviews",
      blockSubTitle:
        "With so much at stake as a business owner, you can’t afford to leave your online reputation to chance. SpotOn’s online review management software makes it easy to monitor all of your online reviews in one place and get automated alerts when new reviews are posted—so that you can not only monitor your reputation, but improve it.",
      blockImg: "prof-e.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/demo",
      },
    },
  ],
};
export const professionalOverviewPartIntegration = {
  title: "Payment terminals fit for your business",
  integrationsContent: [
    {
      title: "SpotOn Terminal",
      subtext:
        "Get personal with the all-in-one smart device for payments, marketing, and customer insights.",
      contentImg: "terminal.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal",
      },
    },
    {
      title: "SpotOn Mobile",
      subtext: "Accept payments your way, wherever business takes you.",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/mobile",
      },
    },
  ],
};

export const professionalOverviewCTA = {
  title: "Speak with a growth professional.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/demo",
  },
};

export const professionalOverviewVideo = {
  title: " SpotOn tools in action",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  posterWrapperClass: "relative",
  videoUrlId: "tOvV35haJYM",
};

export const professionalOverviewTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const professionalOverviewArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const professionalOverviewFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};
