import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import {
  professionalOverviewHero,
  professionalOverviewWhiteGlove,
  professionalOverviewMultiSection,
  professionalOverviewCTA,
  professionalOverviewVideo,
  professionalOverviewTestimonials,
  professionalOverviewSubverticals,
  professionalOverviewPartIntegration,
  professionalOverviewTrusted,
} from "../data/professional-overview-data";
import {
  minuteManPressSlides,
  heroSuccess,
} from "../data/success-stories-data";
import { plumbersArticles } from "../data/plumbers-data";
import Hero from "../components/Hero/hero";
// import WhiteGlove from "../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../components/LargeFeatures/LargeFeatures";
// import LargeCta from "../components/CTAs/LargeCta";
// import SuccessStories from "../components/SuccessStories/SuccessStories";
// import VideoSection from "../components/VideoSection/VideoSection";
// import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
// import Subverticals from "../components/SubVerticals/Subverticals";
// import IntegrationsSection from "../components/Integrations/IntegrationsSection";
// import TrustedNumbers from "../components/TrustedNumbers/TrustedNumbers";
// import Articles from "../components/Articles/Articles";
import ogImage from "../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../components/TestimonialReviews/TestmonialReviews")
);
const Subverticals = loadable(() =>
  import("../components/SubVerticals/Subverticals")
);
const IntegrationsSection = loadable(() =>
  import("../components/Integrations/IntegrationsSection")
);
const TrustedNumbers = loadable(() =>
  import("../components/TrustedNumbers/TrustedNumbers")
);
const Articles = loadable(() => import("../components/Articles/Articles"));
const CapterraBanner = loadable(() =>
  import("../components-v2/CapterraBanner")
);

const whiteGloveImport = "bto-white-glove.png";

const ProfessionalOverview = () => {
  return (
    <Layout>
      <SEO
        title="Point of Sale (POS) System for Small Businesses & Contractors | SpotOn"
        description="SpotOn's small business & professional services POS software offers payment processing, appointment booking,  marketing solutions & more. Get a demo!"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/professional-services/"
      />
      <Hero sectionData={professionalOverviewHero} circleBg={false} />
      <TrustedNumbers numbersArray={professionalOverviewTrusted} />
      <WhiteGlove
        sectionData={professionalOverviewWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <Subverticals sectionData={professionalOverviewSubverticals} />
      <LargeFeatures
        sectionData={professionalOverviewMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <VideoSection sectionData={professionalOverviewVideo} />
      <SuccessStories sectionData={heroSuccess} slides={minuteManPressSlides} />
      <IntegrationsSection sectionData={professionalOverviewPartIntegration} />
      <Articles
        sectionData={plumbersArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-smb-pos.png"
        title={`Rated the <span class="text-primary">best</span> point-of-sale for small businesses<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.softwareadvice.com/small-business-pos-systems/spoton-restaurant/references?c=&c=&c=&sc=6319897"
      />
      <LargeCta sectionData={professionalOverviewCTA} />
      <TestmonialReviews sectionData={professionalOverviewTestimonials} />
      {/* <PartnerLogos /> */}
      {/* <ByTheNumbers sectionData={byTheNumbers} /> */}
      {/* <Faqs sectionData={professionalOverviewFaqs} /> */}
      {/*      <Articles
        sectionData={professionalOverviewArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default ProfessionalOverview;
